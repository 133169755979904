import { v4 as uuidV4 } from 'uuid';
import {
  AchievementRewardType,
  IAchievementGetDTO,
  IAchievementPostDTO,
  INotification,
} from 'dorian-shared/types/achievement/Achievement';
import { Story } from '../../../dorian-shared/types/story/Story';
import { IAchievementModal } from './AchievementsModalTypes';

const initialNotification: INotification = {
  title: '',
  message: '',
};

export const newAchievement = (id: number|string = uuidV4()): IAchievementModal => ({
  id,
  name: '',
  description: '',
  displayName: '',
  icon: '',
  isAdded: true,
  isManual: false,
  unavailableAfter: undefined,
  check: {
    variableId: 0,
    operator: '',
    value: '',
  },
  usedInStory: [],
  isOwnedByUser: false,
  notification: initialNotification,
  reward: {
    contentId: '',
    type: 0,
    delaySec: 0,
  },
});

export const convertAchievementToPostDTO = (achievement: IAchievementModal | IAchievementGetDTO): IAchievementPostDTO => {
  const { notification } = achievement;
  if (notification) {
    notification.title = notification.title?.trim() ?? '';
    notification.message = notification.message?.trim() ?? '';
  }

  const isRewardContentNone = achievement.reward?.contentId === ''
    || achievement.reward?.contentId === null
    || achievement.reward?.contentId === undefined;

  const iconId = achievement.icon === '' ? null : achievement.icon;

  return {
    name: achievement.name,
    icon: iconId,
    displayName: achievement.displayName,
    description: achievement.description,
    isManual: achievement.isManual,
    check: { ...achievement.check },
    unavailableAfter: achievement.unavailableAfter,
    notification,
    reward: {
      contentId: isRewardContentNone
        ? null
        : achievement.reward?.contentId ?? null,
      delaySec: achievement.reward?.delaySec ?? 0,
      type: achievement.reward?.type ?? AchievementRewardType.None,
    },
  };
};

export const convertAchievementGetDTOToModal = (achievement: IAchievementGetDTO): IAchievementModal => {
  const { notification } = achievement;
  if (notification) {
    notification.title = notification.title?.trim() ?? '';
    notification.message = notification.message?.trim() ?? '';
  }

  return {
    id: achievement.id,
    name: achievement.name ?? '',
    icon: achievement.icon ?? '',
    displayName: achievement.displayName ?? '',
    description: achievement.description ?? '',
    isManual: achievement.isManual ?? false,
    check: {
      ...achievement.check,
      value: achievement.check.value,
    },
    isAdded: false,
    usedInStory: achievement.usedInStory ?? [],
    isOwnedByUser: achievement.isOwnedByUser ?? false,
    unavailableAfter: achievement.unavailableAfter,
    notification,
    reward: achievement.reward
      ? { ...achievement.reward }
      : { contentId: '', type: 0, delaySec: 0 },
  };
};

export const getEpisodeTitleIcons = (episode: Story) => `${episode.isLocked ? ' 🔒' : ''}${episode.isBonus ? ' 🎁' : ''}`;
